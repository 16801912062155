.portfolio {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,minmax(0,1fr));
  grid-column-gap: 80px;
  grid-row-gap: 75px;
  grid-auto-rows: min-content;
  max-width: 1400px;
  margin: 0 auto;

  & > * {
    width: 100%;
    padding-bottom: 133.333%;
    position: relative;

    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: cover;
    }

    .title {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      padding: 7%;
      text-align: center;
      z-index: 100;
      transition: opacity ease 100ms;
      background: rgba(255, 255, 255, 0.1);
      font-family: itc-avant-garde-gothic-pro;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0em;
      text-transform: none;
      line-height: 1.4em;
      font-size: 3.3rem;
      color: white;

      &:hover {
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1,minmax(0,1fr));
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    .title {
      font-size: 2.2rem;
    }
  }
}

.portfolio-section {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  .title {
    text-align: center;
    white-space: pre-wrap;
    color: black;
    font-size: 2.2rem;
    font-family: itc-avant-garde-gothic-pro;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    padding-bottom: 3.3vmax;
  }
}